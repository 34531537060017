import React from "react";
import styled from "styled-components";
import Map from "../../components/map";

const Wrapper = styled.section``;

// all props used from singe-ride.js
const TabMap = ({
  routeFrom,
  routeTo,
  urlMap,
  recommendedPlaces,
  mapZoom,
  browserHeight,
  browserWidth,
  isMobile,
  warning,
}) => {
  return (
    <Wrapper>
      <Map
        routeFrom={routeFrom}
        routeTo={routeTo}
        urlMap={urlMap}
        recommendedPlaces={recommendedPlaces}
        mapZoom={mapZoom}
        browserHeight={browserHeight}
        browserWidth={browserWidth}
        isMobile={isMobile}
        warning={warning}
      />
    </Wrapper>
  );
};

export default TabMap;
