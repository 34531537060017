import React from "react";
import styled from "styled-components";
import {
  mainWhite,
  lightGray,
  innerWidth,
  screen,
} from "../../components/common/variables";
import BackgroundImage from "gatsby-background-image";
import { timeConvert, timeFormat } from "../../components/common/helpers";

const Wrapper = styled.section`
  background: ${mainWhite};
  padding-bottom: 45px;
  overflow: hidden;
  @media ${screen.xsmall} {
    padding-top: 70px;
    padding-bottom: 0;
    min-height: calc(100vh - 260px);
  }
  @media ${screen.small} {
    padding-bottom: 70px;
  }
  @media ${screen.medium} {
    min-height: calc(100vh - 241px);
  }

  .ride-details-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 30px;
    @media ${screen.xsmall} {
      display: flex;
      padding: 0 70px;
      flex-direction: column;
    }
    @media ${screen.small} {
      flex-direction: row;
    }
    @media ${screen.large} {
      padding: 0;
    }

    &__hidden-width {
      display: none;
      @media ${screen.small} {
        display: block;
        margin-right: 60px;
        max-width: 40px;
        width: 100%;
        visibility: hidden;
      }
    }

    &__texts {
      @media ${screen.xsmall} {
        width: 85%;
      }
      @media ${screen.small} {
        max-width: 450px;
        width: 100%;
        margin-right: 50px;
      }
      color: ${lightGray};
      @media ${screen.xlarge} {
        margin-right: 100px;
      }

      .measure {
        font-weight: 300;
        font-size: 1.13rem;
        @media ${screen.xsmall} {
          font-size: 1.3rem;
        }
        span {
          font-weight: 500;
        }
      }

      .description {
        margin-top: 38px;
        @media ${screen.xsmall} {
          margin-top: 60px;
        }

        h1 {
          font-size: 1.6rem;
          font-weight: 600;
          margin-bottom: 20px;
          @media ${screen.xsmall} {
            font-size: 1.7rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 1.8rem;
          }
        }
        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 20px;
          @media ${screen.xsmall} {
            font-size: 1.6rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 1.7rem;
          }
        }
        h3 {
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom: 20px;
          @media ${screen.xsmall} {
            font-size: 1.5rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 1.6rem;
          }
        }
        h4 {
          font-size: 1.3rem;
          font-weight: 600;
          margin-bottom: 20px;
          @media ${screen.xsmall} {
            font-size: 1.4rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 1.5rem;
          }
        }
        h5 {
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 20px;
          @media ${screen.xsmall} {
            font-size: 1.3rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 20px;
          @media ${screen.xsmall} {
            font-size: 1.2rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 1.3rem;
          }
        }

        p {
          font-size: 1rem;
          margin-bottom: 25px;
          @media ${screen.xsmall} {
            font-size: 1.05rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 1.1rem;
          }
        }
        a {
          text-decoration: underline;
          color: ${lightGray};
        }

        ul,
        ol {
          padding-left: 20px;
          list-style-position: inside;
          li {
            list-style-type: disc;
            font-size: 1rem;
            margin-bottom: 25px;
            @media ${screen.xsmall} {
              font-size: 1.05rem;
              margin-bottom: 30px;
            }
            @media ${screen.medium} {
              font-size: 1.1rem;
            }
          }
        }

        ol {
          li {
            list-style-type: decimal;
          }
        }
      }
    }

    &__img {
      display: none;

      @media ${screen.small} {
        display: block;
        width: 100%;
        height: 270px;
        margin-right: calc(-50vw + 50%);
      }
      @media ${screen.small} {
        height: 370px;
      }
      @media ${screen.medium} {
        height: 450px;
        margin-left: 90px;
      }
      @media ${screen.large} {
        height: 580px;
      }
      @media ${screen.xlarge} {
        height: 640px;
        margin-left: 45px;
      }

      &--sm {
        display: block;
        height: 180px;
        margin-bottom: 38px;
        border-radius: 0 0 95px 0;
        overflow: hidden;
        @media ${screen.xsmall} {
          display: none;
        }
      }

      &--md {
        height: 350px;
        display: none;
        @media ${screen.xsmall} {
          display: block;
          margin-top: 35px;
        }
        @media ${screen.small} {
          display: none;
        }
      }

      &--lg {
        display: none;
        &::before {
          background-position: calc(-6vw) 0 !important;
        }

        @media ${screen.small} {
          display: block;
        }
      }
    }
  }
`;

// all props used from singe-ride.js
const TabDetails = ({
  featuredImglandscape,
  distance,
  approximateTime,
  elevationGain,
  description,
  type,
}) => {
  return (
    <Wrapper>
      <BackgroundImage
        className="ride-details-wrapper__img ride-details-wrapper__img--sm"
        fluid={featuredImglandscape.fluid}
      ></BackgroundImage>
      <div className="ride-details-wrapper">
        <div className="ride-details-wrapper__hidden-width"></div>
        <div className="ride-details-wrapper__texts">
          <p className="measure">
            Distance:{" "}
            <span>
              {distance} {distance > 1 ? "kms" : "km"}
            </span>
          </p>
          <p className="measure">
            Approximate time:{" "}
            <span>
              {timeConvert(approximateTime)}{" "}
              {timeFormat(approximateTime) === "mins"
                ? "minutes"
                : timeFormat(approximateTime) === "hrs"
                ? "hours"
                : "hour"}
            </span>
          </p>
          <p className="measure">
            Type: <span>{type}</span>
          </p>
          <p className="measure">
            Elevation gain: <span>{elevationGain} metres</span>
          </p>

          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        </div>

        <BackgroundImage
          className="ride-details-wrapper__img ride-details-wrapper__img--lg"
          fluid={featuredImglandscape.fluid}
        ></BackgroundImage>
      </div>
      <BackgroundImage
        className="ride-details-wrapper__img ride-details-wrapper__img--md"
        fluid={featuredImglandscape.fluid}
      ></BackgroundImage>
    </Wrapper>
  );
};

export default TabDetails;
