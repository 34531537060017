import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  innerWidth,
  lightGray,
  dirtyWhite,
  mainWhite,
  mainPink,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/arrow.svg";
import { getVimeoId } from "../components/common/helpers";
import TabDetails from "./ride-tabs/details";
import TabMap from "./ride-tabs/map";
import TabPhotos from "./ride-tabs/photos";
import TabVideo from "./ride-tabs/video";

const Wrapper = styled.div`
  position: relative;
  z-index: 99;
  margin-top: ${(props) => (props.warning ? `-48px` : `0`)};
  @media ${screen.xsmall} {
    padding-top: 68px;
    z-index: 1;
    margin-top: 0;
  }
  @media ${screen.medium} {
    padding-top: 68px;
  }

  #tab {
    background: ${mainWhite};
    padding: 35px 17px 0 17px;
    @media ${screen.xsmall} {
      background: ${dirtyWhite};
      padding: 0;
    }

    .tab-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      @media ${screen.xsmall} {
        padding: 60px 70px 0 70px;
      }
      @media ${screen.large} {
        padding: 60px 0 0 0;
      }

      &__back-arrow {
        min-width: 24px;
        width: 24px;
        height: 24px;
        margin-top: 10px;
        @media ${screen.xsmall} {
          margin-right: 60px;
          margin-top: 9px;
          width: 30px;
          height: 30px;
        }
        @media ${screen.small} {
          margin-top: 6px;
          width: 40px;
          height: 40px;
        }
        @media ${screen.withCursor} {
          &:hover svg {
            .arrow-fill {
              fill: ${mainPink};
            }
          }
        }

        svg {
          transform: scale(-1);
          .arrow-fill {
            transition: fill 0.19s ease-out;
            fill: ${lightGray};
          }
        }
      }

      &__ride-info {
        width: 100%;
        @media ${screen.xsmall} {
          width: auto;
        }

        .ride-title {
          color: ${lightGray};
          font-weight: 500;
          font-size: 1.63rem;
          line-height: 35px;
          margin-left: 42px;
          @media ${screen.xsmall} {
            font-size: 2.3rem;
            margin-left: 0;
          }
          @media ${screen.small} {
            font-size: 2.6rem;
          }
        }

        .tab-control-list {
          display: flex;
          margin-top: 30px;
          justify-content: space-between;
          margin-left: -24px;
          padding: 0 12px;
          @media ${screen.xsmall} {
            margin-left: -40px;
            padding: 0;
          }
          @media ${screen.small} {
            margin-top: 40px;
          }

          &__each {
            color: ${lightGray};
            cursor: pointer;
            font-size: 0.88rem;
            font-weight: 500;
            padding: 0 13px 12px 13px;
            position: relative;
            margin: 0 3px;
            transition: all 0.13s ease-out;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
              margin: 0 20px;
              padding: 0 16px 12px 16px;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
              padding: 0 20px 15px 20px;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainPink};
              }
            }

            &--active {
              color: ${mainPink};
              font-weight: 600;
              &::after {
                content: "";
                width: 100%;
                height: 4px;
                background: ${mainPink};
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }
  }
`;

const RidePage = (props) => {
  const ogImg =
    props.data.content.data.featured_image_landscape &&
    props.data.content.data.featured_image_landscape.url;

  // default selected tab is details
  const [selectedTab, setSelectedTab] = useState("details-tab");

  // is video ready to play
  const [isVideoReady, setIsVideoReady] = useState(false);

  // is video currently playing
  const [isPlaying, setIsPlaying] = useState(false);

  // play/pause icon state
  const [playIcon, setPlayIcon] = useState(false);

  // width/height prop passed from layout component
  const { browserHeight, browserWidth, warning } = props;

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  // select tab from by its id element
  const changeTab = (e) => {
    setSelectedTab(e.target.id);
  };

  //
  const handlePlay = () => {
    setPlayIcon(true);
  };

  const handlePause = () => {
    setPlayIcon(false);
  };

  const ogUrl = typeof window !== "undefined" && window.location.href;

  // all content from prismic query
  const {
    title,
    distance,
    approximate_time,
    type,
    elevation_gain,
    description,
    featured_image_landscape,
    // featured_image_portrait,
    from,
    to,
    map_zoom,
    google_map_direction_url,
    recommended_place,
    video_url,
    video_url_portrait,
    photo_list,
    title_tag,
    meta_description,
  } = props.data.content.data;

  // fixed vimeo url for embed
  const vimeoLandscapeURL = `https://player.vimeo.com/${getVimeoId(video_url)}`;

  const vimeoPortraitURL = video_url_portrait
    ? `https://player.vimeo.com/${getVimeoId(video_url_portrait)}`
    : `https://player.vimeo.com/${getVimeoId(video_url)}`;

  const isMobile = browserWidth <= 650;

  return (
    <>
      <SEO
        title={title_tag}
        description={meta_description}
        url={ogUrl}
        image={ogImg}
      />
      <Wrapper
        isVideoReady={isVideoReady}
        browserHeight={browserHeight}
        warning={warning}
      >
        <div id="tab">
          <div className="tab-wrapper">
            <Link to="/rides/" className="tab-wrapper__back-arrow">
              <Arrow />
            </Link>
            <div className="tab-wrapper__ride-info">
              <h1 className="ride-title">{title.text} - Bike Ride</h1>
              <ul className="tab-control-list">
                <li
                  onClick={changeTab}
                  id="map-tab"
                  className={
                    selectedTab === "map-tab"
                      ? "tab-control-list__each tab-control-list__each--active"
                      : "tab-control-list__each"
                  }
                >
                  MAP
                </li>
                <li
                  onClick={changeTab}
                  id="details-tab"
                  className={
                    selectedTab === "details-tab"
                      ? "tab-control-list__each tab-control-list__each--active"
                      : "tab-control-list__each"
                  }
                >
                  DETAILS
                </li>
                <li
                  onClick={changeTab}
                  id="video-tab"
                  className={
                    selectedTab === "video-tab"
                      ? "tab-control-list__each tab-control-list__each--active"
                      : "tab-control-list__each"
                  }
                >
                  VIDEO
                </li>
                <li
                  onClick={changeTab}
                  id="photos-tab"
                  className={
                    selectedTab === "photos-tab"
                      ? "tab-control-list__each tab-control-list__each--active"
                      : "tab-control-list__each"
                  }
                >
                  PHOTOS
                </li>
              </ul>
            </div>
          </div>
        </div>

        {selectedTab === "map-tab" && (
          <TabMap
            routeFrom={from}
            routeTo={to}
            urlMap={google_map_direction_url}
            recommendedPlaces={recommended_place}
            mapZoom={map_zoom}
            browserHeight={browserHeight}
            browserWidth={browserWidth}
            isMobile={isMobile}
            warning={warning}
          />
        )}

        {selectedTab === "details-tab" && (
          <TabDetails
            featuredImglandscape={featured_image_landscape}
            distance={distance}
            approximateTime={approximate_time}
            elevationGain={elevation_gain}
            description={description}
            type={type}
          />
        )}

        {selectedTab === "photos-tab" && <TabPhotos photoList={photo_list} />}

        {selectedTab === "video-tab" && (
          <TabVideo
            vimeoLandscapeURL={vimeoLandscapeURL}
            vimeoPortraitURL={vimeoPortraitURL}
            isVideoReady={isVideoReady}
            setIsVideoReady={setIsVideoReady}
            handlePlay={handlePlay}
            handlePause={handlePause}
            isPlaying={isPlaying}
            playIcon={playIcon}
            togglePlay={togglePlay}
            browserHeight={browserHeight}
            isMobile={isMobile}
          />
        )}
      </Wrapper>
    </>
  );
};

export default RidePage;

export const dataQuery = graphql`
  query ($id: String!) {
    content: prismicRide(id: { eq: $id }) {
      id
      data {
        title {
          text
        }
        distance
        approximate_time
        type
        elevation_gain
        description {
          html
        }
        featured_image_landscape {
          url
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        from {
          latitude
          longitude
        }
        to {
          latitude
          longitude
        }
        map_zoom
        google_map_direction_url
        recommended_place {
          name
          address {
            latitude
            longitude
          }
          short_description
          website_url
        }
        video_url
        video_url_portrait
        photo_list {
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
