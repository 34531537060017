import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import {
  mainGreen,
  mainWhite,
  dirtyWhite,
  mainPink,
  screen,
} from "../../components/common/variables";
import { IoIosPlay, IoIosPause } from "react-icons/io";
import Spinner from "../../images/svg/spinner-video.svg";

const Wrapper = styled.section`
  background: ${dirtyWhite};

  .video-wrapper {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 220px);
    height: ${props =>
      props.browserHeight
        ? `${props.browserHeight - 128}px`
        : `calc(100vh - 220px)`};
    @media ${screen.xsmall} {
      height: calc(100vh - 260px);
    }
    @media ${screen.medium} {
      height: calc(100vh - 241px);
    }

    &__video {
      position: absolute;
      top: 50%;
      left: 50%;
      height: ${props => `${props.browserHeight}px !important`};
      width: ${props => `${props.browserHeight * 2}px !important`};
      transform: translate(-50%, -50%);
      @media ${screen.xsmall} {
        height: ${props => `${props.browserHeight * 2}px !important`};
      }

      &--landscape {
      }

      &--portrait {
        height: ${props => `${props.browserHeight * 1.2}px !important`};
      }
    }
  }

  #video-spinner {
    background: ${dirtyWhite};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    opacity: ${props => (props.isVideoReady ? 0 : 1)};
    pointer-events: ${props => (props.isVideoReady ? "none" : "all")};
    transition: opacity 1.1s ease-out;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 86px;
      @media ${screen.xsmall} {
        max-width: 106px;
      }
      @media ${screen.large} {
        max-width: 126px;
      }
    }
  }

  .play-btn {
    background: ${mainPink};
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    width: 80px;
    height: 80px;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background 0.19s ease-out, transform 0.4s ease-out,
      top 0.3s ease-out, left 0.3s ease-out;
    @media ${screen.withCursor} {
      &:hover {
        background: ${mainGreen};
      }
    }

    svg {
      color: ${mainWhite};
      display: block;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: calc(50% + 2px);
      transform: translate(-50%, -50%);
    }

    &--playing {
      top: 20px;
      left: 20px;
      transform: translate(0, 0);

      svg {
        left: 50%;
      }
    }
  }
`;

// all props used from singe-ride.js
const TabVideo = ({
  vimeoPortraitURL,
  vimeoLandscapeURL,
  isVideoReady,
  setIsVideoReady,
  handlePlay,
  handlePause,
  isPlaying,
  playIcon,
  togglePlay,
  browserHeight,
  isMobile,
}) => {
  return (
    <Wrapper isVideoReady={isVideoReady} browserHeight={browserHeight}>
      <div className="video-wrapper">
        {isMobile ? (
          <ReactPlayer
            className="video-wrapper__video video-wrapper__video--portrait"
            preload="true"
            url={vimeoPortraitURL}
            onReady={() => {
              setIsVideoReady(true);
            }}
            onPlay={handlePlay}
            onPause={handlePause}
            playing={isPlaying}
            config={{ vimeo: { playerOptions: { quality: "1080p" } } }}
          />
        ) : (
          <ReactPlayer
            className="video-wrapper__video video-wrapper__video--landscape"
            preload="true"
            url={vimeoLandscapeURL}
            onReady={() => {
              setIsVideoReady(true);
            }}
            onPlay={handlePlay}
            onPause={handlePause}
            playing={isPlaying}
            config={{ vimeo: { playerOptions: { quality: "1080p" } } }}
          />
        )}

        <div id="video-spinner">
          <Spinner />
        </div>
        <div
          className={playIcon ? "play-btn play-btn--playing" : "play-btn"}
          onClick={togglePlay}
        >
          {playIcon ? <IoIosPause /> : <IoIosPlay />}
        </div>
      </div>
    </Wrapper>
  );
};

export default TabVideo;
