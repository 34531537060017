import React, { useState } from "react";
import styled from "styled-components";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import {
  mainGreen,
  mainPink,
  mainWhite,
  dirtyWhite,
  subFont,
  screen,
} from "../common/variables";
import mapStyle from "./style";
import Spinner from "../../images/svg/spinner-video.svg";
import markerMainIcon from "../../images/sub-marker.png";
import { IoMdOpen } from "react-icons/io";

const Wrapper = styled.div`
  position: relative;
  display: block;

  height: ${(props) =>
    props.browserHeight
      ? `${props.browserHeight - 128}px`
      : `calc(100vh - 128px)`};
  width: 100%;
  @media ${screen.xsmall} {
    height: ${(props) =>
      props.warning
        ? `${props.browserHeight - 285}px`
        : `${props.browserHeight - 232}px`};
  }
  @media ${screen.medium} {
    height: ${(props) =>
      props.warning
        ? `${props.browserHeight - 285}px`
        : `${props.browserHeight - 241}px`};
  }

  #map-spinner {
    background: ${dirtyWhite};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
    opacity: ${(props) => (props.isMapReady ? 0 : 1)};
    pointer-events: ${(props) => (props.isMapReady ? "none" : "all")};
    transition: opacity 1.1s ease-out;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 86px;
      @media ${screen.xsmall} {
        max-width: 106px;
      }
      @media ${screen.large} {
        max-width: 126px;
      }
    }
  }

  #google-map {
    width: 100%;
    height: 100%;
  }
  .gm-style .gm-style-iw-t::after {
    top: -33px;
    @media ${screen.xsmall} {
      top: -50px;
    }
  }

  .gm-style-iw {
    padding: 13px !important;
    top: -33px;
    @media ${screen.xsmall} {
      top: -50px;
    }

    .gm-style-iw-d {
      overflow: visible !important;
    }

    .info-window {
      color: ${mainPink};
      padding: 1px 0;

      &__title {
        display: block;
        font-weight: 400;
        font-size: 0.6rem;
        @media ${screen.medium} {
          font-size: 0.9rem;
        }
      }

      &__description {
        display: block;
        margin-top: 3px;
        font-size: 0.65rem;
        line-height: 1.5;
        @media ${screen.medium} {
          font-size: 0.75rem;
        }
      }

      a {
        display: block;
        text-decoration: underline;
        margin-top: 3px;
        font-size: 0.65rem;
        color: ${mainGreen};
        @media ${screen.medium} {
          font-size: 0.75rem;
        }
      }
    }
  }

  #google-map-url {
    position: absolute;
    bottom: 0;
    left: 0;
    display: table;

    @media ${screen.xsmall} {
      bottom: 50px;
      right: 38px;
      left: auto;
    }

    a {
      padding: 8px 9px;
      background: ${mainPink};
      color: ${mainWhite};
      display: block;
      font-family: ${subFont};
      font-size: 0.85rem;
      line-height: 0;
      margin-top: 2px;
      border-radius: 0 5px 5px 0;
      transition: background 0.19s ease-out;
      @media ${screen.xsmall} {
        border-radius: 30px;
        font-size: 1.1rem;
        padding: 12px 16px 8px 16px;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainGreen};
        }
      }
      svg {
        display: inline-block;
        vertical-align: middle;
        font-size: 0.9rem;
        margin-top: -4px;
        margin-left: 5px;
        @media ${screen.small} {
          font-size: 1.05rem;
        }
      }
    }
  }
`;

const regCoordinates = /[/0-9.-]{7,},[0-9.-/]{7,}/g;

// actual waypoints to use for map route
// only silly hack, it could cause a bug specially if google changed their url format
function getWaypoints(url) {
  let getCoordinates = [...url.match(regCoordinates)];

  const split = getCoordinates
    .map((item) => {
      return item.replace(/\//g, "").split(",");
    })
    .slice(0, getCoordinates.length - 1);

  const waypoints = split.map((item) => {
    return { location: { lat: Number(item[0]), lng: Number(item[1]) } };
  });

  return waypoints;
}

const TrailMap = ({
  routeFrom,
  routeTo,
  mapZoom,
  recommendedPlaces,
  urlMap,
  browserHeight,
  browserWidth,
  isMobile,
  warning,
}) => {
  // const [distance, setDistance] = useState(null);
  // const [duration, setDuration] = useState(null);
  const [route, setRoute] = useState(null);
  // if google map script is ready
  const [isGoogleMapsAPILoaded, setIsGoogleMapsAPILoaded] = useState(false);

  // is map ready to load
  const [isMapReady, setIsMapReady] = useState(false);

  // object that display the map info window when marker is clicked
  const [selectedPlace, setSelectedPlace] = useState(null);

  // get zoom number in google map url
  const regZoom = /[0-9.]+z/g;
  const zoom = Number(urlMap.match(regZoom)[0].replace(/z/g, ""));

  // get all coordinates in google map url
  const regCoordinates = /[/0-9.-]{7,},[0-9.-/]{7,}/g;
  let getCoordinates = [...urlMap.match(regCoordinates)];

  // get the center position in google map url
  const center = getCoordinates[getCoordinates.length - 1].split(",");

  // map marker size change depending on device screen
  const markerWidth = browserWidth >= 650 ? 43 : 30;
  const markerHeight = browserWidth >= 650 ? 55 : 38;

  const waypoints = getWaypoints(urlMap);

  return (
    <Wrapper
      browserHeight={browserHeight}
      isMapReady={isMapReady}
      warning={warning}
    >
      <div id="map-spinner">
        <Spinner />
      </div>

      <LoadScript
        onLoad={() => {
          setIsGoogleMapsAPILoaded(true);
        }}
        // google api stored on netlify dashboard
        googleMapsApiKey={process.env.GATSBY_GOOGLE_API_KEY}
      >
        {isGoogleMapsAPILoaded && (
          <GoogleMap
            id="google-map"
            options={{
              styles: mapStyle,
              disableDefaultUI: true,
              gestureHandling: "greedy",
            }}
            // zoom={mapZoom}
            zoom={isMobile ? zoom - 1.1 : zoom}
            center={{
              lat: center ? Number(center[0]) : routeFrom.latitude,
              lng: center ? Number(center[1]) : routeFrom.longitude,
            }}
            // center={{
            //   lat: routeFrom.latitude,
            //   lng: routeFrom.longitude,
            // }}
            onLoad={() => {
              setIsMapReady(true);
            }}
          >
            {recommendedPlaces &&
              recommendedPlaces.map(
                (place, i) =>
                  isGoogleMapsAPILoaded &&
                  place.address.latitude &&
                  place.address.longitude && (
                    <Marker
                      key={i}
                      position={{
                        lat: place.address.latitude,
                        lng: place.address.longitude,
                      }}
                      icon={{
                        url: markerMainIcon,
                        scaledSize: new window.google.maps.Size(
                          markerWidth,
                          markerHeight
                        ),
                      }}
                      onClick={() => {
                        setSelectedPlace(place);
                      }}
                    />
                  )
              )}

            {recommendedPlaces && selectedPlace && (
              <InfoWindow
                position={{
                  lat: selectedPlace.address.latitude,
                  lng: selectedPlace.address.longitude,
                }}
                onCloseClick={() => {
                  setSelectedPlace(null);
                }}
              >
                <div className="info-window">
                  <span className="info-window__title">
                    {selectedPlace.name}
                  </span>
                  <span className="info-window__description">
                    {selectedPlace.short_description}
                  </span>
                  {selectedPlace.website_url && (
                    <a
                      href={selectedPlace.website_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Website
                    </a>
                  )}
                </div>
              </InfoWindow>
            )}

            <DirectionsService
              options={{
                origin: {
                  lat: routeFrom.latitude,
                  lng: routeFrom.longitude,
                },
                destination: {
                  lat: routeTo.latitude,
                  lng: routeTo.longitude,
                },
                travelMode: "BICYCLING",

                waypoints: waypoints,
                // optimizeWaypoints: true,
              }}
              callback={(res) => {
                if (route) return false;
                setRoute(res);
                // setDistance(res.routes[0].legs[0].distance.text);
                // setDuration(res.routes[0].legs[0].duration.text);
              }}
            />
            {isGoogleMapsAPILoaded && route && (
              <DirectionsRenderer
                directions={route}
                options={{
                  preserveViewport: true,
                  polylineOptions: {
                    strokeColor: mainPink,
                    strokeOpacity: 1,
                    strokeWeight: 4,
                  },
                  suppressMarkers: true,
                }}
                waypoints
              />
            )}
          </GoogleMap>
        )}
      </LoadScript>

      {urlMap && (
        <div id="google-map-url">
          <a
            href={urlMap}
            rel="noopener noreferrer"
            target="_blank"
            title="Google map"
          >
            VIEW ON GOOGLE MAPS
            <IoMdOpen />
          </a>
        </div>
      )}
    </Wrapper>
  );
};

export default TrailMap;
