import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { mainWhite, screen } from "../../components/common/variables";

const Wrapper = styled.section`
  background: ${mainWhite};
  @media ${screen.xsmall} {
    min-height: calc(100vh - 260px);
  }
  @media ${screen.medium} {
    min-height: calc(100vh - 241px);
  }

  .ride-photo-list {
    @media ${screen.xsmall} {
      display: flex;
      flex-wrap: wrap;
      padding-top: 14px;
      margin: 0 -7px;
    }

    &__each {
      margin: 0 0 10px 0;
      @media ${screen.xsmall} {
        margin: 0 7px 14px 7px;
        width: calc(50% - 14px);
      }
      @media ${screen.small} {
        width: calc(33.33% - 14px);
      }

      &:last-child {
        margin: 0;
        @media ${screen.xsmall} {
          margin: 0 7px 14px 7px;
        }
      }
    }
  }
`;

// props used from singe-ride.js
const TabPhotos = ({ photoList }) => {
  return (
    <Wrapper>
      <ul className="ride-photo-list">
        {photoList.map((item, i) => (
          <li key={i} className="ride-photo-list__each">
            <GatsbyImage
              image={item.image.gatsbyImageData}
              alt={item.image.alt || "Gallery"}
            />
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default TabPhotos;
